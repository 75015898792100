.RegisterHeader{
   display: flex;
   align-items: center;
   span{
      font-family: $third_font;
      color: $color_orange;
      font-size: 2.6rem;
      margin-right: 1rem;
   }
}

.RegisterHeader_Title{
   color: $color_white_blue;
   margin-top: 1rem;
   font-size: 18px;
   width: 80%;
   text-align: center;
}

.RegisterHeader_Subtitle{
   color: $color_orange;
   margin-bottom: 1.5rem;
   margin-top: .5rem;
   font-size: 14px;
   width: 80%;
   text-align: center;
}

.RegisterRoute{
   display: flex;
   height: 100vh;
   align-items: center;
   justify-content: center;

   .RegisterRoute_Left{
      flex: .4;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   
      .RegisterRoute_Form_Button{
         background-color: $color_blue;
         color: white;
      }   

      .RegisterRoute_Form{
         width: 80%;
      }

      .RegisterRoute_Conditions{
         width: 80%;
         color: $color_white_blue;
         margin-bottom: 1.5rem;
         margin-top: .5rem;
         font-size: 12px;
         text-align: center;
         a, span{
            cursor: pointer;
            color: $color_blue;
         }
      }
      .RegisterRoute_Account{
         width: 80%;
         border-top: 1px solid $color_border;
         color: $color_white_blue;
         padding-top: 1.5rem;
         margin-top: .5rem;
         text-align: center;
         font-size: 14px;
         span{
            color: $color_blue;
            &:hover{
               cursor: pointer;
            }
         }
      }
   }
   
   .RegisterRoute_Image{
      flex: .6;

      img{
         height: 100%;
         width: 100%;
      }
   }

   
}



@media (max-width: $breakpoint-laptop-sm) {
   .RegisterRoute{
      .RegisterRoute_Left{
         flex: .6;
      }
      .RegisterRoute_Image{
         flex: .4;
      }
   }
   
}

@media (max-width: $breakpoint-tablet) {
   .RegisterRoute{
      .RegisterRoute_Left{
         flex: 1;
      }
      .RegisterRoute_Image{
         flex: 0;
      }
   }
   
}