
.HeaderRouter {
   display: flex;
   justify-content: space-between;
   /* border-bottom: 1px solid red; */
   box-shadow: 1px 7px 11px -4px rgba(221, 221, 221, 0.76);

   .HeaderRight{
     display: flex;
     align-items: center;
   }

   .HeaderLeft{
     display: flex;
     align-items: center;
    h1{
      margin: 1rem;
      font-size: 1.3rem;
      span{
        font-family: $third_font;
        color: $color_orange;
      }
    }

    .HeaderVersion{
      font-size: .7rem;
      margin: 1rem;
    }
   }
 }

 .HeaderRouterNavBar {
   margin: 1rem;
   display: flex;
   list-style-type: none;
 }
 
 .HeaderRouterNavBar li {
   padding: .5rem 1rem;
 }

 .Header_username {
   display: flex;
   margin-right: .5rem;
   padding: .25rem .5rem;
   border-radius: 10px;
   cursor: pointer;
 
   span {
     text-overflow: ellipsis;
     overflow: hidden;
     white-space: nowrap;
     padding: 0 1rem;
   }
 
   &:hover {
     background-color: #d7d7d7;
   }
 }
 
  .Header_PopUp {
    display: flex;
    flex-direction: column;
    align-items: center;
  
    .Header_PopUp_Image {
      width: 40px !important;
      height: auto !important;
    }
  
    .Header_PopUp_Name {
      margin-top: 1rem;
      font-weight: bold;
    }
  
    .Header_PopUp_Email {
      padding-bottom: 1rem;
      color: $color_empty_state;
    }
  }

  @media (max-width: 960px) {

    .HeaderRouter {
   
      .HeaderLeft{

       h1{
         span{
           display: none;
         }
       }
      }
    }

    .Header_username {
      span {
        display: none;
      }
    
    }
  }
