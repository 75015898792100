::-webkit-scrollbar {
  width: 0px;
  height: 10px;
}

.clearfix:before,
.clearfix:after {
    content: ".";    
    display: block;    
    height: 0;    
    overflow: hidden; 
}
.clearfix:after { clear: both; }
.clearfix { zoom: 1; } /* IE < 8 */

.invisible-scrollbar::-webkit-scrollbar {
  display: none;
}
/* Track */

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 10px;
}

/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
  background: #9e9e9e;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.InputField {
  width: 100% !important;
  min-width: 100% !important;
  border: none !important;
  padding: 0 1rem;
}

.InputField:focus {
  outline: none;
}

div[tabindex="-1"]:focus {
  outline: 0;
}

.breadcrumTitle {
  margin: 1rem !important;
  font-size: 1.3rem !important;
}

.PageContainer {
  margin: .5rem 1.5rem;
}

.SmallPageContainer{
  max-width: 950px;
  margin: auto;
}