
input[type='file'] {
   width: 0.1px;
    height: 0.1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -1;
 }

.Field{
   flex-grow: 1;
   margin : 0 1rem 1rem 0;
   .FieldLabel{
      font-weight: bold;
      color: $color_dark_blue;
   }

   .FieldValue{
      border-bottom: 1px solid #cfcfcf; 
      padding-bottom: .2rem;
      span{
      padding: .7rem 0; 
         display: block;
      
      }

      pre{
         padding: .7rem 0; 
         display: block;
         font-family: $primary_font;
         margin: 0;
         white-space: pre-wrap;
      }

      .FieldInput_ input{
         border: none;
         padding-left: 0;
      }

      .FieldTextArea{
         width: 100%;
         height: 15rem;
         background-color: white;
         border: none;
         resize: none;

         &:focus{
            outline: none;
            border: 1px solid #5E9ED6;
         }
      }

      .FieldInputFile{
         display: flex; 

         div{
            flex: 1 !important;
         }
      }
   }

   .react-datepicker-wrapper{
      width: 100% !important;
   }

   .FieldCleave{
      width: 100%;
      margin: 0;
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      outline: 0;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      text-align: left;
      line-height: 1.21428571em;
      padding: .67857143em 1em;
      background: #fff;
      border: 1px solid rgba(34,36,38,.15);
      color: rgba(0,0,0,.87);
      border-radius: .28571429rem;
      -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
      transition: border-color .1s ease,-webkit-box-shadow .1s ease;
      transition: box-shadow .1s ease,border-color .1s ease;
      transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
      -webkit-box-shadow: none;
      box-shadow: none;
   }

   .FieldCleave_{
      width: 100%;
      margin: 0;
      max-width: 100%;
      -webkit-box-flex: 1;
      -ms-flex: 1 0 auto;
      flex: 1 0 auto;
      outline: 0;
      -webkit-tap-highlight-color: rgba(255,255,255,0);
      text-align: left;
      line-height: 1.21428571em;
      padding: .67857143em 0em;
      background: #fff;
      border: none;
      color: rgba(0,0,0,.87);
      border-radius: .28571429rem;
      -webkit-transition: border-color .1s ease,-webkit-box-shadow .1s ease;
      transition: border-color .1s ease,-webkit-box-shadow .1s ease;
      transition: box-shadow .1s ease,border-color .1s ease;
      transition: box-shadow .1s ease,border-color .1s ease,-webkit-box-shadow .1s ease;
      -webkit-box-shadow: none;
      box-shadow: none;
   }

   .FieldSelect_ > div{
      border: none !important;
   }

   .FieldDate input{
      border: none !important;
   }

   .FieldSearch div input{
      background-color: white !important;
      border: none !important;
   }
}

.PersonalHeader{
   margin: 0rem 1rem 1rem 1rem ;
   font-size: 1.3rem ;
   color: $color_blue ;
   font-size: $secondary_font ;
   font-weight: bold;
}